import React from "react"
import { Link , graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"
import Image from "../components/image"

export const articleListQuery = graphql`
  query articleListQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "article-post" } } }
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            slug
						title
						categories {
							category
						}
						featuredImage {
							extension
							relativePath
							childImageSharp {
								fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
							}
						}
          }
        }
      }
    }
  }
`
class ArticleIndex extends React.Component {
  render() {
    
    const { data } = this.props

    const posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node.frontmatter.date)
      .map(edge =>
        <PostCard key={edge.node.id} data={edge.node} />
      )
    
    return (
      <Layout className="article-page">
        <SEO
          titlel="記事"
          description="記事ページ"
        />
		<Image
	  	  filename="../assets/articles_top.png"
          className="top-page-image"
		  fadeIn={false}
      	/>
        <h1>記事</h1>
		<div className="article-page-content">
			<h2>About</h2>
			<div>
				<Link to="/about/shimamuni" className="button">しまむにって？</Link>
				<Link to="/about/endangered_lang" className="button btn-secondary">危機言語とは</Link>
			</div>
		</div>
		<div className="article-page-content">
			<h2>Columns</h2>
        	<div className="grids col-1 sm-2 lg-3">
        	  {posts}
        	</div>
		</div>
      </Layout>
    )
  }
}

export default ArticleIndex
