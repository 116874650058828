import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const PostCard = ({ data }) => (
  <article className={"post-card" + ((data.frontmatter.featuredImage && data.frontmatter.featuredImage.extension !== "gif") ? "" : " no-image-post")}>
    <Link to={data.frontmatter.slug}>
		{data.frontmatter.featuredImage ?
			(data.frontmatter.featuredImage.extension === "gif" ?
			 "" :
			 (
    	      <Img
    	        fluid={data.frontmatter.featuredImage.childImageSharp.fluid} 
    	        objectFit="cover"
    	        objectPosition="50% 50%"
    	        alt={data.frontmatter.title + ' - Featured image'}
    	        className="featured-image"
				fadeIn={false}
    	      />
			  )
			) : ""
		}
		<div className="post-content">
    	  <h2 className="title"><Link to={data.frontmatter.slug}>{data.frontmatter.title}</Link></h2>
    	  <p className="meta"><time>{data.frontmatter.date}</time>
			{data.frontmatter.categories ?
				data.frontmatter.categories.map((category, index) =>
				<span className="tag tag-primary">{category.category}</span>
			): ""}
		  </p>
    	</div>
    </Link>
  </article>
)

export default PostCard
